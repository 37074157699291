var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-9 pr-4"
  }, [_vm.news.weather.current.weather ? _c('div', {
    staticClass: "block-rounded bg-image mb-4 weather-block"
  }, [_c('div', {
    staticClass: "weather-block-top block-content d-flex justify-content-between align-items-center py-6 px-5"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "mb-0 mr-5 text-black-50"
  }, [_c('i', {
    staticClass: "fa fa-4x",
    class: _vm._f("weatherIcon")(_vm.news.weather.current.weather[0].icon)
  })]), _c('p', {
    staticClass: "display-4 text-black-75 mb-0"
  }, [_vm._v(_vm._s(_vm.news.weather.current.weather[0].main))])]), _c('div', [_c('p', {
    staticClass: "font-size-h1 mb-0 text-right text-white"
  }, [_c('strong', [_vm._v(_vm._s(Math.round(_vm.news.weather.current.temp - 273)) + " ")]), _vm._v("° "), _c('span', {
    staticClass: "text-white-75"
  }, [_vm._v("C")])]), _c('p', {
    staticClass: "font-size-h3 mb-0 text-right text-white"
  }, [_c('small', [_vm._v("Feels like")]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(Math.round(_vm.news.weather.current.feels_like - 273)) + " ")]), _vm._v("° "), _c('span', {
    staticClass: "text-white-75"
  }, [_vm._v("C")])])])]), _c('div', {
    staticClass: "block-content p-0 bg-body-light"
  }, [_c('div', {
    staticClass: "row no-gutters text-center"
  }, _vm._l(_vm.news.weather.daily.slice(1, 7), function (day) {
    return _c('div', {
      key: day.dt,
      staticClass: "col-6 col-xl-2 border-right border-bottom p-3"
    }, [_c('p', {
      staticClass: "font-size-h3 mb-3"
    }, [_vm._v(_vm._s(_vm._f("date")(day.dt, 'dddd', 'X')))]), _c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-6"
    }, [_c('i', {
      staticClass: "fa text-black-50 fa-3x",
      class: _vm._f("weatherIcon")(day.weather[0].icon)
    })]), _c('div', {
      staticClass: "col-6"
    }, [_c('div', {
      staticClass: "font-size-h3"
    }, [_c('strong', [_vm._v(_vm._s(Math.round(day.temp.day - 273)) + "° C")])])])])]);
  }), 0)])]) : _c('div', [_c('Spinner', {
    attrs: {
      "text": "Loading weather..."
    }
  })], 1), _c('h2', {
    staticClass: "text-white"
  }, [_vm._v("News")]), _vm.news.local.length > 0 ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.news.local.slice(0, 4), function (article) {
    return _c('div', {
      key: article.publishedAt,
      staticClass: "col-3 border-right"
    }, [_c('div', {
      staticClass: "block block-transparent kiosk-block"
    }, [_c('div', {
      staticClass: "kiosk-news-image mb-2",
      style: "background-image: url(".concat(article.urlToImage, ")")
    }), _c('h3', {
      staticClass: "text-white mb-2"
    }, [_vm._v(_vm._s(article.title))]), _c('p', {
      staticClass: "text-white mb-1"
    }, [_c('span', {
      staticClass: "font-size-h5"
    }, [_vm._v(_vm._s(article.source.name))]), _vm._v(" | "), _c('i', {
      staticClass: "fal fa-clock"
    }), _c('em', [_vm._v(" " + _vm._s(_vm._f("timeago")(article.publishedAt)))])]), _c('p', {
      staticClass: "font-size-h5 text-white"
    }, [_vm._v(_vm._s(article.description))])])]);
  }), 0) : _vm._e()]), _vm.kiosk.isLondon && _vm.news.travel.tube ? _c('div', {
    staticClass: "col-3 pl-4"
  }, [_c('h2', {
    staticClass: "text-white mb-0"
  }, [_vm._v("Travel")]), _c('p', {
    staticClass: "text-white"
  }, [_vm._v("Last updated: " + _vm._s(_vm._f("date")(_vm.news.lastUpdated, 'DD/MM/YYYY HH:mm:ss')))]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-fill"
  }, _vm._l(_vm.news.travel.tube.filter(function (line) {
    return line.lineStatuses[0].statusSeverity < 10;
  }), function (line) {
    return _c('div', {
      key: line.id,
      staticClass: "local-travel-block",
      class: line.id
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('div', [line.lineStatuses[0].statusSeverity < 10 ? _c('i', {
      staticClass: "fa fa-lg fa-exclamation"
    }) : _c('i', {
      staticClass: "fa fa-lg fa-check"
    })]), _c('div', {
      staticClass: "ml-3 text-right"
    }, [_c('p', {
      staticClass: "font-size-h4 mb-0"
    }, [_vm._v(_vm._s(line.name))]), line.lineStatuses[0].statusSeverity < 10 ? _c('p', {
      staticClass: "mb-0 font-w600 text-uppercase"
    }, [_vm._v(" " + _vm._s(line.lineStatuses[0].statusSeverityDescription) + " ")]) : _vm._e(), line.lineStatuses[0].reason ? _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(line.lineStatuses[0].reason.slice(0, 120)) + _vm._s(line.lineStatuses[0].reason.length > 120 ? '...' : '') + " ")]) : _vm._e()])])]);
  }), 0)])]) : _c('div', {
    staticClass: "col-3 pl-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("Travel")]), _c('GoogleMap', {
    attrs: {
      "map-config": _vm.mapOptions,
      "markers": _vm.mapMarkers,
      "traffic": "",
      "height": "750"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }