<template>
  <div>
    <div class="row">
      <div class="col-9 pr-4">
        <div v-if="news.weather.current.weather" class="block-rounded bg-image mb-4 weather-block">
          <div class="weather-block-top block-content d-flex justify-content-between align-items-center py-6 px-5">
            <div class="d-flex align-items-center">
              <p class="mb-0 mr-5 text-black-50">
                <i class="fa fa-4x" :class="news.weather.current.weather[0].icon | weatherIcon"></i>
              </p>
              <p class="display-4 text-black-75 mb-0">{{ news.weather.current.weather[0].main }}</p>
            </div>
            <div>
              <p class="font-size-h1 mb-0 text-right text-white">
                <strong>{{ Math.round(news.weather.current.temp - 273) }} </strong>° <span class="text-white-75">C</span>
              </p>
              <p class="font-size-h3 mb-0 text-right text-white">
                <small>Feels like</small> <strong>{{ Math.round(news.weather.current.feels_like - 273) }} </strong>°
                <span class="text-white-75">C</span>
              </p>
            </div>
          </div>
          <div class="block-content p-0 bg-body-light">
            <div class="row no-gutters text-center">
              <div v-for="day in news.weather.daily.slice(1, 7)" :key="day.dt" class="col-6 col-xl-2 border-right border-bottom p-3">
                <p class="font-size-h3 mb-3">{{ day.dt | date('dddd', 'X') }}</p>
                <div class="row align-items-center">
                  <div class="col-6">
                    <i class="fa text-black-50 fa-3x" :class="day.weather[0].icon | weatherIcon"></i>
                  </div>
                  <div class="col-6">
                    <div class="font-size-h3">
                      <strong>{{ Math.round(day.temp.day - 273) }}° C</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <Spinner text="Loading weather..." />
        </div>

        <h2 class="text-white">News</h2>
        <div v-if="news.local.length > 0" class="row">
          <div v-for="article in news.local.slice(0, 4)" :key="article.publishedAt" class="col-3 border-right">
            <div class="block block-transparent kiosk-block">
              <div class="kiosk-news-image mb-2" :style="`background-image: url(${article.urlToImage})`" />

              <h3 class="text-white mb-2">{{ article.title }}</h3>
              <p class="text-white mb-1">
                <span class="font-size-h5">{{ article.source.name }}</span> | <i class="fal fa-clock"></i>
                <em> {{ article.publishedAt | timeago }}</em>
              </p>
              <p class="font-size-h5 text-white">{{ article.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="kiosk.isLondon && news.travel.tube" class="col-3 pl-4">
        <h2 class="text-white mb-0">Travel</h2>
        <p class="text-white">Last updated: {{ news.lastUpdated | date('DD/MM/YYYY HH:mm:ss') }}</p>
        <div class="d-flex">
          <div class="flex-fill">
            <div
              v-for="line in news.travel.tube.filter(line => line.lineStatuses[0].statusSeverity < 10)"
              :key="line.id"
              class="local-travel-block"
              :class="line.id"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i v-if="line.lineStatuses[0].statusSeverity < 10" class="fa fa-lg fa-exclamation"></i>
                  <i v-else class="fa fa-lg fa-check"></i>
                </div>
                <div class="ml-3 text-right">
                  <p class="font-size-h4 mb-0">{{ line.name }}</p>
                  <p v-if="line.lineStatuses[0].statusSeverity < 10" class="mb-0 font-w600 text-uppercase">
                    {{ line.lineStatuses[0].statusSeverityDescription }}
                  </p>
                  <p v-if="line.lineStatuses[0].reason" class="mb-0">
                    {{ line.lineStatuses[0].reason.slice(0, 120) }}{{ line.lineStatuses[0].reason.length > 120 ? '...' : '' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-3 pl-4">
        <h2 class="text-white">Travel</h2>
        <GoogleMap :map-config="mapOptions" :markers="mapMarkers" traffic height="750" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import GoogleMap from '@/components/GoogleMap';
import Spinner from '@/components/Spinner';

export default {
  name: 'KioskLocalNews',
  components: {
    GoogleMap,
    Spinner
  },
  filters: {
    weatherIcon: function (value) {
      if (!value) return null;

      const map = {
        '01d': 'fa-sun',
        '02d': 'fa-clouds-sun',
        '03d': 'fa-cloud',
        '04d': 'fa-clouds',
        '09d': 'fa-cloud-drizzle',
        '10d': 'fa-cloud-showers-heavy',
        '11d': 'fa-thunderstorm',
        '13d': 'fa-snowflakes',
        '50d': 'fa-fog',

        '01n': 'fa-moon',
        '02n': 'fa-clouds-moon',
        '03n': 'fa-cloud',
        '04n': 'fa-clouds',
        '09n': 'fa-cloud-drizzle',
        '10n': 'fa-cloud-showers-heavy',
        '11n': 'fa-thunderstorm',
        '13n': 'fa-snowflakes',
        '50n': 'fa-fog'
      };

      const icon = map[value];

      return icon || null;
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      kiosk: 'kiosk/kiosk',
      asset: 'kiosk/asset',
      news: 'kiosk/news',
      loadingAction: 'asset/loadingAction'
    }),
    mapOptions() {
      return {
        center: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        zoom: 22,
        disableDefaultUI: true
      };
    },
    mapMarkers() {
      return [
        {
          pos: {
            lat: parseFloat(this.asset.address.latitude),
            lng: parseFloat(this.asset.address.longitude)
          },
          title: this.asset.siteName
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.local-travel-block {
  padding: 8px 20px;
}

.kiosk-news-image {
  background-size: cover;
  background-position: 50%;
  height: 180px;
}

.default_line {
  background-color: #607d8b;
}

.bakerloo {
  background-color: #ae6118;
  color: #ffffff;
}

.central {
  background-color: #e41f1f;
  color: #ffffff;
}
.circle {
  background-color: #f8d42d;
  color: #113892;
}
.district {
  background-color: #007229;
  color: #ffffff;
}

.dlr {
  background-color: #00bbb4;
  color: #ffffff;
}

.hammersmith-city {
  background-color: #e899a8;
}

.jubilee {
  background-color: #686e72;
  color: #ffffff;
}

.metropolitan {
  background-color: #893267;
  color: #ffffff;
}

.northern {
  background-color: #000000;
  color: #ffffff;
}

.london-overground {
  background-color: #e86a10;
  color: #ffffff;
}

.piccadilly {
  background-color: #0450a1;
  color: #ffffff;
}

.victoria {
  background-color: #009fe0;
  color: #ffffff;
}

.waterloo-city {
  background-color: #70c3ce;
  color: #113892;
}

.tfl-rail {
  background-color: #0019a8; /*#091f72; #2070b0;*/
  color: #ffffff;
}

.trams,
.tram {
  background-color: #79c23f;
  color: #fff;
}

.emirates-air-line {
  background-color: #d71a21;
  color: #fff;
}

.woolwich,
.thames {
  background-color: #033973;
  color: #fff;
}

.weather-block {
  .weather-block-top {
    background: linear-gradient(60deg, rgb(248 248 248 / 90%), rgb(248 248 248 / 90%), rgb(202 20 45 / 90%), rgb(202 20 45 / 90%));
  }

  .fa-sun {
    color: #f89d2d !important;
  }

  .fa-clouds-sun:after {
    color: #f89d2d !important;
    opacity: 1;
  }

  .fa-moon {
    color: #949494 !important;
  }

  .fa-clouds-moon:after {
    color: #949494 !important;
    opacity: 1;
  }

  .fa-cloud-showers-heavy:after {
    color: #2d6bf8 !important;
  }

  .fa-cloud-drizzle:after {
    color: #2d6bf8 !important;
  }

  .fa-cloud-showers:after {
    color: #2d6bf8 !important;
  }

  .fa-clouds {
    color: #a0a0a0 !important;
  }
}
</style>
